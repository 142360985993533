import React, { useState } from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from '../Theme/theme';
import Devis from '../components/PagesComponent/Devis/Devis';
import { useTheme } from '../components/Container';
import { navigate } from 'gatsby';

const isBrowser = typeof window !== `undefined`;
const DemanderDevis = () => {
  const [open, setOpen] = useState(true);
  const devisState = useTheme();
  const handleClose = () => {
    setOpen(prev => !prev);
    if (isBrowser) window.history.back();
    else navigate('/');
    devisState.toggle();
  };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Devis open={open} handleClose={handleClose} />
    </ThemeProvider>
  );
};

export default DemanderDevis;
